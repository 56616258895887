import { z } from 'zod';

import { directusService, limiter, zodKeys } from '@/services/api.service';

export const getVinCheckPage = limiter.wrap(async () => {
  const { data }: any = await directusService.get('/items/vin_page', {
    params: {
      fields: [
        'header_title',
        'header_subtitle',
        'header_image',
        'footer_call_to_action.*',
        'footer_call_to_action_image',
      ],
      filter: {
        status: {
          _eq: 'published',
        },
      },
    },
  });

  return data[0];
});

export const getVinVehicle = limiter.wrap(async (vin: string) => {
  const { data }: any = await directusService.get('/items/recall_vehicle', {
    params: {
      fields: ['vin', 'campaign_number'],
      filter: {
        vin: {
          _contains: vin,
        },
      },
    },
  });

  return data;
});

const ModelSchema = z.object({
  name: z.string(),
  id: z.union([z.string(), z.number()]),
});

const CampaignSchema = z.object({
  campaign_number: z.union([z.string(), z.number()]),
  model: ModelSchema,
  type: z.string(),
  name: z.string(),
  description: z.string(),
});

const CampaignsResponseSchema = z.array(CampaignSchema);

export type Campaigns = z.infer<typeof CampaignSchema>;

export const getCampaigns = limiter.wrap(async (campaigns: string[]) => {
  const { data }: any = await directusService.get('/items/recall_campaigns', {
    params: {
      fields: zodKeys(CampaignSchema),
      filter: {
        campaign_number: {
          _in: campaigns,
        },
        status: {
          _eq: 'published',
        },
      },
      limit: -1,
    },
  });

  const response = CampaignsResponseSchema.safeParse(data);

  if (response.error) {
    return [];
  }

  return response.data;
});

export const createVinCheckLead = async (leadValues: any) => {
  await directusService.post('/items/recall_leads', leadValues);
};
