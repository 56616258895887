'use client';
import { FormControl } from '@chakra-ui/form-control';
import { SearchIcon } from '@chakra-ui/icons';
import { Input } from '@chakra-ui/input';
import { Box, Heading, Stack, Text } from '@chakra-ui/layout';
import { FormErrorMessage } from '@chakra-ui/react';
import React from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';

import Styles from './VinSearch.module.scss';

type VinSearchProps = {
  searchInput: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
  isError: boolean;
  isValidInput: boolean;
};

const VinSearchForm = ({
  searchInput,
  handleInputChange,
  handleSubmit,
  isError,
  isValidInput,
}: VinSearchProps) => {
  return (
    <>
      <Box maxW={{ base: '100%', lg: '750px' }} mb={9}>
        <Box className={Styles.headingElement}>
          <Heading
            as="h2"
            className={Styles.titleXl}
            marginBottom={{ base: 4, md: 6 }}
          >
            Enter your VIN
          </Heading>
        </Box>
        <Text>
          Upon submitting your VIN number we will notify you if your vehicle is
          part of any safety operation or service action campaigns.
        </Text>
      </Box>

      <Box maxWidth={{ base: '100%', lg: '550px' }}>
        <Stack
          gap={{ base: 6, lg: 12 }}
          direction={{ base: 'column', lg: 'row' }}
          alignItems={'flex-end'}
        >
          <FormControl isInvalid={!!searchInput && !isValidInput}>
            <Input
              placeholder="VIN Number"
              className={Styles.formInput}
              variant="flushed"
              value={searchInput}
              onChange={handleInputChange}
            />
            {!!searchInput && !isValidInput && (
              <FormErrorMessage>You have entered invalid VIN.</FormErrorMessage>
            )}
          </FormControl>
          <ButtonCustom
            onClick={handleSubmit}
            extraClass={Styles.ctaBtn}
            disabled={!isValidInput}
          >
            <SearchIcon /> Search
          </ButtonCustom>
        </Stack>

        {isError && (
          <Text marginTop={10}>
            There are currently no outstanding campaigns on your vehicle.
          </Text>
        )}
      </Box>
    </>
  );
};

export default VinSearchForm;
